import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UploadService from "../doaram/service/file-upload.service";
import { State, City } from "country-state-city";
import { Button, Form, Select } from "antd";
import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import Part from "./shared/becomepart/part";
import { useDispatch } from "react-redux";
import { Pagination } from "antd";

const { Option } = Select;

function Listedngo() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedItems, setSelectedItems] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [totalElements, setTotalElements] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [ngoList, setNgoList] = useState([]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN").map(
      (state) => state.name
    );
    setStates(indianStates);
    const listngoformData = new FormData();
    listngoformData.append("page", page - 1);
    listngoformData.append("size", size);

    UploadService.create("/ngo/getAllNGOAccounts", listngoformData).then(
      (response) => {
        setNgoList(response.data.data.ngoDetails);
        setTotalElements(response.data.data.totalElements);
      }
    );
  }, [page]);
  const handleStateChange = (value) => {
    setSelectedState(value);

    const selectedStateInfo = State.getStatesOfCountry("IN").find(
      (state) => state.name === value
    );
    if (selectedStateInfo) {
      const citiesData = City.getCitiesOfState(
        selectedStateInfo.countryCode,
        selectedStateInfo.isoCode
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(citiesData);
    } else {
      setCities([]);
    }

    form.setFieldsValue({ city: "" }); // Clear selected city when state changes
    setSelectedCity("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  const onsubmit = (values) => {
    const filterngoformData = new FormData();

    filterngoformData.append("page", page - 1);
    filterngoformData.append("size", size);

    if (values.state !== undefined) {
      filterngoformData.append("state", values.state);
    }
    if (values.city !== undefined) {
      filterngoformData.append("city", values.city);
    }
    if (values.rating !== undefined) {
      filterngoformData.append("rating", values.rating);
    }
    if (values.category !== undefined) {
      filterngoformData.append("category", values.category);
    }
    UploadService.create("/ngo/getAllNGOAccounts", filterngoformData).then(
      (response) => {
        if (response.data.data !== null) {

          setNgoList(response.data.data.ngoDetails);
          setTotalElements(response.data.data.totalElements);
        } else {
          setNgoList([]);
        }
      }
    );
  };
  const dynamicngosite = (ngoid, ngoname) => {
    const ngoSlug = ngoname.toLowerCase().replace(/\s+/g, "-");
    navigate(`/ngo/${ngoSlug}/${ngoid}`);
  };
  return (
    <>
     
      <div style={{ backgroundColor: "#eeeeee" }}>
        <Header />
        {/* <!-- Facts Start --> */}

        <div className="container-fluid mt-5">
          <div className="mb-3 webbody pt-5">
            <div className="container-fluid pt-5 ">
              <div className="row g-5 pt-1">
                <div className="col-md-3 bg-light">
                  <div className="card-body">
                    <div className="col-md-12  pt-1">
                      <p>
                        <i className="fa fa-search"></i>&nbsp;Listed NGOs-Search
                        By:
                      </p>
                      <hr />
                      <Form
                        onFinish={onsubmit}
                        form={form}
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 16,
                        }}
                        style={{
                          maxWidth: 600,
                        }}
                      >
                        <div className="row">
                          <Form.Item
                            className="para b themeCol-orange"
                            name="state"
                            label="State"
                          >
                            <Select
                              placeholder="Select State"
                              onChange={handleStateChange}
                              value={selectedState}
                              showSearch
                            >
                              {states.map((state) => (
                                <Option key={state} value={state}>
                                  {state}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="row">
                          <Form.Item
                            className="para b themeCol-orange"
                            name="city"
                            label="City"
                          >
                            <Select
                              placeholder="Select City"
                              onChange={handleCityChange}
                              showSearch
                              value={selectedCity}
                              disabled={!selectedState}
                            >
                              {cities.map((city) => (
                                <Option key={city.value} value={city.value}>
                                  {city.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="row">
                          <Form.Item
                            className="para b themeCol-orange"
                            name="rating"
                            label="Rating"
                          >
                            <Select
                              isClearable
                              placeholder="--Select Rating--"
                              style={{
                                width: "100%",
                              }}
                              options={[
                                { value: "NGO_BRONZE", label: "Bronze" },
                                { value: "NGO_SILVER", label: "Silver" },
                                { value: "NGO_GOLD", label: "Gold" },
                                { value: "NGO_PLATINUM", label: "Platinum" },
                              ]}
                            />
                          </Form.Item>
                        </div>
                        <div className="row">
                          <Form.Item
                            className="para b themeCol-orange"
                            name="category"
                            label="Category"
                          >
                            <Select
                              isClearable
                              placeholder="--NGO Category--"
                              value={selectedItems}
                              onChange={setSelectedItems}
                              style={{
                                width: "100%",
                              }}
                              options={[
                                { value: "Elderly", label: "Elderly" },
                                { value: "Animals", label: "Animals" },
                                { value: "Children", label: "Children" },
                                {
                                  value: "Cultural Heritage",
                                  label: "Cultural Heritage",
                                },
                                { value: "Education", label: "Education" },
                                { value: "Employment", label: "Employment" },
                                { value: "Environment", label: "Environment" },
                                {
                                  value: "Disaster Relief Works",
                                  label: "Disaster Relief Works",
                                },
                                {
                                  value: "Women Empowerment",
                                  label: "Women Empowerment",
                                },
                                {
                                  value: "Rural Development",
                                  label: "Rural Development",
                                },
                                {
                                  value: "Community Mobilization",
                                  label: "Community Mobilization",
                                },
                                {
                                  value: "Sustainable Livelihood",
                                  label: "Sustainable Livelihood",
                                },
                                {
                                  value: "Differently Abled",
                                  label: "Differently Abled",
                                },
                                { value: "Others", label: "Others" },
                              ]}
                            />
                          </Form.Item>
                        </div>
                        <div className="d-flex justify-content-center">
                          {/* <NavLink to="/ngosignin"> */}
                          <Button
                            htmlType="submit"
                            className="btnnca btn-gold signupbtn ms-2"
                          >
                            <i className="fa fa-filter">&nbsp;Filter</i>
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  <div className="col-md-12 mt-3 card bgprimary">
                    <p>All Listed NGOs</p>
                    <div>
                      {ngoList.map(function (applicant) {
                        return (
                          <div key={applicant.id}>
                            <div
                              className="row mt-3"
                              onClick={() =>
                                dynamicngosite(
                                  applicant.ngoProfile.id,
                                  applicant.ngoProfile.name
                                )
                              }
                            >
                              <div className="col-md-2">
                                <img
                                  src={require("../doaram/assets/da_img/logo1.png")}
                                  className="card-img-top"
                                  style={{ width: "100px", height: "100px" }}
                                  alt="..."
                                />
                              </div>
                              <div className="col-md-5">
                                <h6 className="txt-primary ft-14 text-uppercase ft-bold">
                                  {applicant.ngoProfile.name}
                                </h6>

                                <div className="bd-callout bd-callout-warning">
                                  <small className="text-dark ft-10">
                                    {" "}
                                    {applicant.ngoProfile.about}
                                  </small>
                                </div>
                              </div>

                              <div className="col-md-5">
                                <div className="row">
                                  <div className="col">
                                    <small className="txt-warning ft-12">
                                      {applicant.contactInfo.city}
                                    </small>
                                  </div>
                                  <div className="col">
                                    {/* href={applicant.ngoProfile.staticWebPageLink} "/ngowebsite" */}

                                    <Button
                                      className="btn btn-warning btn-sm"
                                      onClick={() =>
                                        dynamicngosite(
                                          applicant.ngoProfile.id,
                                          applicant.ngoProfile.name
                                        )
                                      }
                                    >
                                      <i className="fa fa-eye"></i>&nbsp;View
                                    </Button>
                                  </div>
                                </div>
                                <div className="row pt-2">
                                  <div className="text-dark ft-10" role="alert">
                                    <i className="fa fa-tasks"></i> &nbsp;{" "}
                                    {applicant.ngoProfile.category}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        );
                      })}
                    </div>
                    <Pagination
                      Current={page}
                      total={totalElements}
                      pageSize={size}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- list End --> */}
        <Part />
        <Footer />
      </div>
    </>
  );
}

export default Listedngo;
