import { Link } from "react-router-dom";

import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import Part from "./shared/becomepart/part";
import { Helmet } from "react-helmet";

import Blog1 from "../imges/blog/blog1.jpg";
import Blog2 from "../imges/blog/blog2.jpg";
import Blog3 from "../imges/blog/blog3.jpg";
import Blog4 from "../imges/blog/blog4.jpg";

function Blog() {
  return (
    <>
      <Helmet>
        <title>Blogs</title>
        <meta
          name="description"
          content="Doaram  publishes NGO blogs related to child education, child welfare and rights, case studies of happy children , child NGO campaigns, provides solutions to child right's issues in India."
        />
        <link rel="canonical" href="https://www.doaram.com/blog" />
      </Helmet>
      <div style={{ backgroundColor: "#eeeeee" }}>
        <Header />
        {/* <!-- Blog Start --> */}
        <div className="container mt-5 webbody pt-5">
          <div>
            <h1 className="mb-4 mt-4 pt-5">Blogs</h1>
          </div>
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="row d-flex justify-content-center">
              {/* <div className="col-md-12 ">
                <div className="input-group  mb-3">
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-warning dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      Category
                    </button>
                    <div
                      className="dropdown-menu p-4 "
                      style={{ maxwidth: "200px" }}
                    >
                      <div className="col ">
                        <div className="list-group text-start">
                          <h4 className="mt-2 p-3">Category</h4>
                          <p
                            id="checkedNumbers"
                            className="text-secondary fs-6"
                          >
                            0/16 selected
                          </p>
                          <div className="blogcategory">
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                name="categoryofcauseall"
                                id="categoryAll"
                                type="checkbox"
                                value=""
                              />
                              All
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                name="categoryofcause"
                                id="category1"
                                type="checkbox"
                                value=""
                              />
                              Advocacy
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category2"
                                value=""
                              />
                              Animals
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category3"
                                value=""
                              />
                              Children
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category4"
                                value=""
                              />
                              Community Mobilization
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category5"
                                value=""
                              />
                              Cultural Heritage
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category6"
                                value=""
                              />
                              Differently Abled
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category7"
                                value=""
                              />
                              Disaster Relief Works
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category8"
                                value=""
                              />
                              Education
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                id="category9"
                                name="categoryofcause"
                                type="checkbox"
                                value=""
                              />
                              Elderly
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                name="categoryofcause"
                                id="category10"
                                type="checkbox"
                                value=""
                              />
                              Employment
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                name="categoryofcause"
                                id="category11"
                                type="checkbox"
                                value=""
                              />
                              Environment
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category12"
                                value=""
                              />
                              Healthcare
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category1"
                                value=""
                              />
                              Others
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category13"
                                value=""
                              />
                              Rural Development
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category14"
                                value=""
                              />
                              Sustainable Livelihood
                            </label>
                            <label className="list-group-item fs-6 b-0 font14">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                name="categoryofcause"
                                id="category15"
                                value=""
                              />
                              Women Empowerment
                            </label>
                          </div>
                          <div className="card-footer">
                            <div className="col">
                              <button
                                type="button"
                                name="btnCancel"
                                id="btnCancel"
                                className="btn btn-light btn-sm"
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col">
                              <Link to="#cause1">
                                <button
                                  type="button"
                                  name="btnApply"
                                  id="btnApply"
                                  className="btn btn-warning btn-sm"
                                >
                                  Apply
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by cause"
                    aria-label="Search by cause"
                    aria-describedby="button-addon2"
                  />
                  <button
                    className="btn btn-warning "
                    type="button"
                    id="button-addon2"
                  >
                    Search
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-md-6 mb-5">
                <div className="post-slide ">
                  <div className="post-img">
                    <img src={Blog1} alt="Blog1" className="blogheight" />
                    <div className="over-layer">
                      <ul className="post-link">
                        <li>
                          <Link
                            to="/blog/a-powerful-medium-to-create-public-awareness-is-through-street-play"
                            className="fa fa-search"
                          ></Link>
                        </li>
                        <li>
                          <Link
                            to="/blog/a-powerful-medium-to-create-public-awareness-is-through-street-play"
                            className="fa fa-link"
                          ></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="post-content">
                    <h3 className="post-title">
                      <Link to="/blog/a-powerful-medium-to-create-public-awareness-is-through-street-play">
                        A powerful medium to create public awareness is street
                        play
                      </Link>
                    </h3>
                    <p className="post-description">
                      Street play is one of the oldest theatre forms where the
                      artists enact in public places with no specific target
                      audience. It is a genre of drama performed with music and
                      dance which is known for its simplicity and creative
                      execution. It serves as a metaphor that captures an image
                      of moral nature which every layman can relate to....
                    </p>
                    <Link
                      to="/blog/a-powerful-medium-to-create-public-awareness-is-through-street-play"
                      className="read-more"
                    >
                      read more
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="post-slide ">
                  <div className="post-img">
                    <img src={Blog2} alt="Blog2" className="blogheight" />
                    <div className="over-layer">
                      <ul className="post-link">
                        <li>
                          <Link
                            to="/blog/moral-science-a-bygone-subject-in-schools-should-be-made-mandatory"
                            className="fa fa-search"
                          ></Link>
                        </li>
                        <li>
                          <Link
                            to="/blog/moral-science-a-bygone-subject-in-schools-should-be-made-mandatory"
                            className="fa fa-link"
                          ></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="post-content">
                    <h3 className="post-title">
                      <Link to="/blog/moral-science-a-bygone-subject-in-schools-should-be-made-mandatory">
                        Moral science, a bygone subject in schools should be
                        made mandatory
                      </Link>
                    </h3>
                    <p className="post-description">
                      Moral education is a value added learning which helps the
                      children to develop a good moral and civic sense to serve
                      the society well. A decade ago, there existed a separate
                      subject called moral science in almost all the schools be
                      it private or a public sector. Sadly, this substantial
                      subject was removed from the curriculum as our education
                      system ....
                    </p>
                    <Link
                      to="/blog/moral-science-a-bygone-subject-in-schools-should-be-made-mandatory"
                      className="read-more"
                    >
                      read more
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="post-slide ">
                  <div className="post-img">
                    <img src={Blog3} alt="Blog3" className="blogheight" />
                    <div className="over-layer">
                      <ul className="post-link">
                        <li>
                          <Link
                            to="/blog/self-help-group-the-role-of-ngos-in-promoting-shgs-and-its-scope"
                            className="fa fa-search"
                          ></Link>
                        </li>
                        <li>
                          <Link
                            to="/blog/self-help-group-the-role-of-ngos-in-promoting-shgs-and-its-scope"
                            className="fa fa-link"
                          ></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="post-content">
                    <h3 className="post-title">
                      <Link to="/blog/self-help-group-the-role-of-ngos-in-promoting-shgs-and-its-scope">
                        Self-help group: the role of NGOs in promoting SHGs and
                        its scope.
                      </Link>
                    </h3>
                    <p className="post-description">
                      A self-help group is an independent voluntary association
                      formed by women from the same socio-economic background
                      who come together to the purpose of solving their common
                      problems. The origin of SHG dates back to 1975 founded by
                      professor Mohammed Yunus, a Nobel laureate ...
                    </p>
                    <Link
                      to="/blog/self-help-group-the-role-of-ngos-in-promoting-shgs-and-its-scope"
                      className="read-more"
                    >
                      read more
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="post-slide ">
                  <div className="post-img">
                    <img src={Blog4} alt="Blog4" className="blogheight" />
                    <div className="over-layer">
                      <ul className="post-link">
                        <li>
                          <Link
                            to="/blog/foot-binding-was-a-symbol-of-status-modesty-and-moral-virtue"
                            className="fa fa-search"
                          ></Link>
                        </li>
                        <li>
                          <Link
                            to="/blog/foot-binding-was-a-symbol-of-status-modesty-and-moral-virtue"
                            className="fa fa-link"
                          ></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="post-content">
                    <h3 className="post-title">
                      <Link to="/blog/foot-binding-was-a-symbol-of-status-modesty-and-moral-virtue">
                        Foot binding was a symbol of status, modesty and moral
                        virtue
                      </Link>
                    </h3>
                    <p className="post-description">
                      Foot binding was one of the unique, ancient practices
                      amongst the upper class women in China. It started as a
                      fashionable impulse during the Tang dynasty where one of
                      the emperor&rsquo;s concubines named Yao Niang performed
                      dance in the court. She entranced the emperor Li Yu
                      dancing...
                    </p>
                    <Link
                      to="/blog/foot-binding-was-a-symbol-of-status-modesty-and-moral-virtue"
                      className="read-more"
                    >
                      read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Blog End --> */}

        <Part />
        <Footer />
      </div>
    </>
  );
}

export default Blog;
