import Construction from "../../assets/icons/construction.png";


function Admin_Dashboard(props) {
  
 

  return (
    <>
   
      <div className=" fontfamilynca">
        <div className="content-header">
          <div className="container-fluid">
            <div
              className="card d-flex align-items-center"
              style={{ height: "80vh" }}
            >
              <img
                src={Construction}
                alt=""
                className="alignimagecenter img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin_Dashboard;
