import React, { useEffect, useState } from "react";
import { Table, Modal, Input, Button, Form, Tooltip, Tabs } from "antd";
import { useNavigate, NavLink } from "react-router-dom";
import UploadService from "../../service/file-upload.service";

function Admin_MembershipHistory() {
  const dataSource = [
    {
      key: "1",
      ngoId: "ngo_educ_the_23",
      membershipLevel: "Gold",
      membershipDuration: "Annual",
      membershipStartDate: "20-11-2022",
      membershipEndDate: "19-11-2023",
      status: "InActive",
    },
    {
      key: "2",
      ngoId: "ngo_educ_the_23",
      membershipLevel: "Platinum",
      membershipDuration: "Annual",
      membershipStartDate: "20-11-2023",
      membershipEndDate: "19-11-2024",
      status: "Active",
    },
  ];
  const columns = [
    {
      title: "NGO_Id",
      dataIndex: "ngoId",
    },
    {
      title: "MembershipLevel",
      dataIndex: "membershipLevel",
    },
    {
      title: "MembershipDuration",
      dataIndex: "membershipDuration",
    },
    {
      title: "MembershipStartDate",
      dataIndex: "membershipStartDate",
    },
    {
      title: "MembershipEndDate",
      dataIndex: "membershipEndDate",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  return (
    <>
      <div className="col-sm-8 row m-1">
        <div className="col">
          <NavLink to="/NGOMembership">
            <button className="btn btn-violet btn-sm">
              <i className="fa fa-arrow-left"></i> Back
            </button>
          </NavLink>
        </div>
      </div>
      <div className="col-md-12 row fontfamilynca    pt-2 px-4">
        <div className="col-sm-6">
          <h6 className="tableheading">MemberShip Histroy:</h6>
        </div>
        <section className="content ">
          <div className="row">
            <div className="mt-3">
              <Table
                columns={columns}
                dataSource={dataSource}
                style={{ padding: "5px" }}
                bordered
                scroll={{
                  x: 1000
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Admin_MembershipHistory;
