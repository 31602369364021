import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import BoyImage from "../../assets/da_img/login1.png";
import { Form, Input, Button, Select, Checkbox, message } from "antd";
import { HomeFilled, HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import axios from "axios";
import SHA256 from "crypto-js/sha256";

const boyImageCom = {
  backgroundImage: `url(${BoyImage}`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
};
function IndividualSignup() {
  const navigate = useNavigate();

  const showerror = (error) => {
    message.error(error.errorFields[0].errors[0]);
  };

  const hashPassword = (password) => {
    const hashedPassword = SHA256(password).toString();
    return hashedPassword;
  };

  const onsubmit = (values) => {
    const signup = {
      userId: values.email,
      userName: "",
      password: hashPassword(values.password),
      termsConditions: values.agreement,
      systemUpdateCommunication: "Email",
      userType: "INDIVIDUAL",
      userRole: "INDIVIDUAL_OPERATIONS",
      associatedEntity: "",
      status: "",
      comments: "",
      passwordSetTime: "",
      numLogin: 0,
      lastLoginTime: "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    sessionStorage.removeItem("key");
    sessionStorage.clear();
    sessionStorage.setItem("da_userCred", JSON.stringify(signup));
    window.signupSession(sessionStorage);
    navigate("/individualsignupselector");
  };

  return (
    <div className="container-fluid bg_dtheme fontfamilynca">
     
      <div className="col-md-12 row ">
        <div className="col-md-6  signupimg" style={boyImageCom}></div>
        <div className="col-md-6">
          <div className="row text-center d-flex justify-content-center ">
            <div className="col-md-3">
              <img
                src={require("./../../assets/da_img/logo1.png")}
                width={100}
                height={100}
                alt="DoAram_Logo"
                className="rounded"
              />
              <br />
              <small className="text-white ft-14 text-sm ">Let's Do Aram</small>
            </div>
          </div>

          <div className="row  d-flex justify-content-center ">
            <Form
              onFinishFailed={showerror}
              layout="vertical"
              autoComplete="off"
              onFinish={onsubmit}
              className="row"
            >
              <div className="col-md-12">
                <div className="row d-flex justify-content-end align-content-center text-end pb-2">
                  <div className="col">
                    <NavLink to="/ngosignin" className="text-white font14">
                      Already User?
                      <span
                        className="text-warning text-sm toolTip top  "
                        data-tip="Login in with your existing account"
                      >
                        &nbsp;<b>Sign In</b>
                      </span>
                    </NavLink>
                    &nbsp;
                    <NavLink to="/" className="btn btn-outline-warning btn-sm">
                      <i className="fa fa-home"></i>
                    </NavLink>
                  </div>
                </div>

                <div className="input-group input-group-sm mt-1 mb-3">
                  <span className="d-input-group-text col">
                    <i
                      className="fa fa-user-circle themeCol-pink"
                      aria-hidden="true"
                    ></i>
                    &nbsp;SIGNUP AS NGO
                  </span>
                </div>

                {/* LOGIN AS */}

                <div className="input-group input-group-sm mt-1 mb-3">
                  <span className="d-input-group-text">
                    <i
                      className="fa fa-envelope  themeCol-pink"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div className="form-floating mb-3 border-0">
                    <Form.Item
                      name="email"
                      label="E-Mail"
                      rules={[
                        { required: true, message: "E-Mail is required" },
                        {
                          type: "email",
                          message: "Enter valid E-Mail address",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="doaram@gmail.com" bordered={false} />
                    </Form.Item>
                  </div>
                  <span className="d-input-group-text border-0"></span>
                </div>

                <div className="input-group input-group-sm mt-1 mb-3">
                  <span className="d-input-group-text">
                    <i className="fa fa-key themeCol-pink" aria-hidden="true"></i>
                  </span>
                  <div className="form-floating mb-3 border-0">
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        { required: true, message: "Password is required" },
                        {
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{9,15}$/,
                          message:
                            "pattern:9-15 length,uppercase,lowecase,special character,number",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        placeholder="*************"
                        bordered={false}
                      />
                    </Form.Item>
                  </div>
                  <span className="d-input-group-text border-0"></span>
                </div>

                <div className="input-group input-group-sm mt-1 mb-3">
                  <span className="d-input-group-text">
                    <i className="fa fa-key  themeCol-pink" aria-hidden="true"></i>
                  </span>
                  <div className="form-floating mb-3 border-0 ">
                    <Form.Item
                      name="confirmpassword"
                      label="Confirm Password"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Confirm Password is required",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject("Password Does not match");
                          },
                        }),
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        placeholder="*************"
                        bordered={false}
                      />
                    </Form.Item>
                  </div>
                  <span className="d-input-group-text border-0"></span>
                </div>

                <div className="col-12 mt-3">
                  <div className="form-check">
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Agree Terms and Conditions "),
                        },
                      ]}
                    >
                      <Checkbox>
                        <span className="font14 text-white ">
                          I accept all{" "}
                          <u>
                            <NavLink
                              to="/TermsConditions"
                              className="text-white"
                            >
                              Terms & Conditions
                            </NavLink>
                          </u>
                        </span>
                        &nbsp;
                        <span className="text-white">
                          <i className="fa fa-question-circle font12"></i>
                        </span>{" "}
                      </Checkbox>
                    </Form.Item>
                  </div>
                </div>

                <div className="d-flex justify-content-center mb-5  pb-5">
                  <p
                    className="font16 text-danger b mb-5 "
                    id="signupmessage"
                  ></p>
                  <Button htmlType="submit" className="btnnca btn-gold btn-lg ">
                    Create Account
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndividualSignup;
