import React, { useState, useEffect } from "react";
import BoyImage from "../../assets/da_img/login1.png";
import { Form, Input, Button, Modal } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { HomeFilled } from "@ant-design/icons";
import UploadService from "../../service/file-upload.service";
import $ from "jquery";

const boyImageCom = {
  backgroundImage: `url(${BoyImage}`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
};

function Corp_Payments() {
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  let viewdata = null;
  let { state } = useLocation();
  if (state != null) {
    viewdata = state;
  } else {
    viewdata = null;
  }

  const [encRequest, setEncRequest] = useState();
  const [accessCode, setAccessCode] = useState();
  const [paymentmodal, setPaymentmodal] = useState(false);

  const onsubmit = (values) => {
    const CorpData = {
      id: 1,
      entityId: userCred.associatedEntity,
      email: values.email,
      phone: values.phone,
      ngoId: viewdata.viewdata.ngoId,
      causeId: viewdata.viewdata.causeId,
      donatedAmount: values.amount,
      gatewayCreditedAmount: 0,
      finalAmountAfterFees: 0,
      doaramPlatformFees: 0,
      orderId: "",
      paymentMode: "",
      status: "",
      pan: "",
      cert80g: "",
      createdDate: "",
      createdBy: "",
      updatedDate: "",
      updatedBy: "",
    };

    const CorpformData = new FormData();
    CorpformData.append("userCred", JSON.stringify(userCred));
    CorpformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    CorpformData.append("corpDonationUserDetails", JSON.stringify(CorpData));
    UploadService.create("corp/payments/cause/createOrder", CorpformData).then(
      (response) => {
        setEncRequest(response.data.data.encReq);
        setAccessCode(response.data.data.accessCode);
        setPaymentmodal(true);
      }
    );
  };
  const validateAmount = (_, value) => {
    const amount = parseFloat(value);

    if (!value) {
      return Promise.reject("Amount is required");
    } else if (isNaN(amount) || amount < 10000) {
      return Promise.reject("Amount should be greater than Rs. 10000");
    } else {
      return Promise.resolve();
    }
  };

  return (
    <>
      <div className="col-md-12 bg_dtheme fontfamilynca">
        <div className="col-md-12 row ">
          <div className="col-md-6  signupimg" style={boyImageCom}></div>

          <div className="col-md-6 mt-3 ">
            <NavLink to="/corpDashboard">
              <button className="btn btn-violet btn-sm">
                <i className="fa fa-arrow-left"></i> Back
              </button>
            </NavLink>
            <div
              className="col-md-9 "
              style={{
                width: "80%",
                display: "flex",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <Form
                layout="vertical"
                initialValues={{ remember: false }}
                onFinish={onsubmit}
                className="row gy-3"
              >
                <div className="col-12 text-center">
                  <div className="col-12 text-center ">
                    <img
                      src={require("./../../assets/da_img/logo1.png")}
                      width={100}
                      height={100}
                      alt="DoAram_Logo"
                      className="rounded mx-auto d-block mt-5"
                    />
                  </div>
                  <small className="text-white font20 text-sm">
                    Let's Do Aram
                  </small>
                </div>

                <div className="input-group">
                  <span className="d-input-group-text">
                    <i
                      className="fa fa-envelope  themeCol-pink"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div className="form-floating  border-0">
                    <Form.Item
                      name="email"
                      label="E-Mail"
                      rules={[
                        { required: true, message: "E-Mail is required" },
                        {
                          type: "email",
                          message: "Enter valid E-Mail address",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Emailid" bordered={false} />
                    </Form.Item>
                  </div>
                  <span className="d-input-group-text border-0"></span>
                </div>

                <div className="input-group my-4">
                  <span className="d-input-group-text">
                    <i
                      className="fa fa-phone  themeCol-pink"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div className="form-floating  border-0">
                    <Form.Item
                      name="phone"
                      label="Phone"
                      rules={[
                        { required: true, message: "Phone number is required" },
                        {
                          pattern: /^\d{10}$/,
                          message: "Please enter a valid 10-digit phone number",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        placeholder="Phonenumber"
                        bordered={false}
                        maxLength={10}
                      />
                    </Form.Item>
                  </div>
                  <span className="d-input-group-text border-0"></span>
                </div>
                <div className="input-group">
                  <span className="d-input-group-text">
                    <i
                      className="fa fa-credit-card themeCol-pink"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <div className="form-floating  border-0">
                    <Form.Item
                      name="amount"
                      label="Amount"
                      //rules={[{ validator: validateAmount }]}
                      hasFeedback
                    >
                      <Input
                        placeholder="Amount in INR(Minumum RS:10,000)"
                        bordered={false}
                      />
                    </Form.Item>
                  </div>
                  <span className="d-input-group-text border-0"></span>
                </div>

                <div className="col-12">
                  <div className="d-flex  justify-content-center mt-3">
                    <Button htmlType="submit" className="bg-yellowgra loginbtn">
                      Pay Now
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Proceed for payment"
        centered
        open={paymentmodal}
        footer={null}
        onCancel={() => setPaymentmodal(false)}
      >
        <>
          <form
            action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
            method="POST"
            name="redirect"
            className="row"
          >
            <input
              type="hidden"
              id="encRequest"
              name="encRequest"
              value={encRequest}
            />
            <input
              type="hidden"
              name="access_code"
              id="access_code"
              value={accessCode}
            />
            <div className="mt-3 ">
              <p>
                {" "}
                With each donation, you're not just giving, but you're becoming
                a part of a story that changes lives.
              </p>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                className="btnnca btn-gold"
                type="submit"
                style={{ padding: "8px", width: "200px" }}
              >
                <i className="fa fa-heart me-1"></i>
                Donate Now
              </button>
            </div>
          </form>
        </>
      </Modal>
      {/* </div> */}
    </>
  );
}

export default Corp_Payments;
