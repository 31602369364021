import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import {
  Table,
  Modal,
  Input,
  Button,
  Form,
  Tooltip,
  Pagination,
  Tag,
} from "antd";
import Nodata from "../../assets/icons/Nocause.png";
import $ from "jquery";
import { useDispatch } from "react-redux";
import UploadService from "../../service/file-upload.service";

function CorpNGOCause() {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isdesModalOpen, setIsdesModalOpen] = useState(false);
  const [causeId, setCauseId] = useState();
  const [addinfoRecord, setAddinfoRecord] = useState();
  const [reRendertable, setRerendertable] = useState();

  let tablelength = dataSource.length;

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  let viewdata = null;
  let { state } = useLocation();

  if (state != null) {
    viewdata = state;
  } else {
    viewdata = null;
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const NgoCauseformData = new FormData();
  useEffect(() => {
    NgoCauseformData.append("userCred", JSON.stringify(userCred));
    NgoCauseformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    NgoCauseformData.append("ngoId", viewdata.viewdata.ngoProfile.id);
    NgoCauseformData.append("page", JSON.stringify(page - 1));
    NgoCauseformData.append("size", JSON.stringify(size));

    UploadService.create("corp/causesWithInterest/get", NgoCauseformData).then(
      (response) => {
        if (response.data !== null) {
          setDataSource(response.data.data.causeInterestList);
        }
        setTotalElements(response.data.data.totalElements);
      }
    );
  }, [page,reRendertable]);
  function formatDate(date) {
    const d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-");
  }
  const showCausgePage = (record) => {
    const causeSlug = record.cause.title.toLowerCase().replace(/\s+/g, "-");
    const url = `/corp/cause/${causeSlug}/${record.cause.causeId}`;
    window.open(url, "_blank");
  };

  const viewProfile = (record) => {
    const ngoSlug = viewdata.viewdata.ngoProfile.name
      .toLowerCase()
      .replace(/\s+/g, "-");
    const url = `/corp/ngo/${ngoSlug}/${record.cause.entityId}`;
    window.open(url, "_blank");
  };

  const columns = [
    {
      title: "Title",
      dataIndex: ["cause", "title"],
      width: 90,
      render: (text, record) => (
        <a onClick={() => showCausgePage(record)} style={{ cursor: "pointer" }}>
          {text}
        </a>
      ),
    },
    {
      title: " Cause Id",
      dataIndex: ["cause", "causeId"],
      width: 90,
    },

    {
      title: "Category",
      dataIndex: ["cause", "category"],
      width: 90,
    },
    // {
    //   title: "Location",
    //   dataIndex: ["cause", "city"],
    //   render: (text, record) => {
    //     const city = record.cause.city;
    //     const state = record.cause.state;
    //     return `${city}, ${state}`;
    //   },
    //   width: 90,
    // },
    {
      title: "Duration",
      dataIndex: "cause",
      render: (text, record) => {
        const startDate = new Date(record.cause.startDate);
        const endDate = new Date(record.cause.endDate);
        const startDateFormat = `${startDate.getDate()}.${
          startDate.getMonth() + 1
        }.${startDate.getFullYear()}`;
        const endDateFormat = `${endDate.getDate()}.${
          endDate.getMonth() + 1
        }.${endDate.getFullYear()}`;

        return `${startDateFormat} - ${endDateFormat}`;
      },
      width: 120,
    },
    // {
    //   title: "Link",
    //   dataIndex: ["cause", "causeLink"],
    //   width: 90,
    //   render: (text, record) => (
    //     <span
    //       className="btn btn-orange btn-sm para"
    //       onClick={() => showCausgePage(record)}
    //     >
    //       <i className="fas fa-external-link-alt"></i>{" "}
    //     </span>
    //   ),
    // },
    {
      title: "Amount",
      dataIndex: ["cause", "goalAmount"],
      render: (text) => <span>&#x20B9; {text}</span>,
      width: 90,
    },
    {
      title: "Action",
      key: "operation",
      width: 40,
      render: (record) => (
        <div className="d-flex">
          <Tooltip title="View NGO Profile">
            <a onClick={() => viewProfile(record)}>
              <span className="btn  btn-info btn-sm para">
                <i className="fas fa fa-globe text-white"></i>
              </span>
            </a>
          </Tooltip>

          <Tooltip title="View Cause">
            <a onClick={() => showCausgePage(record)}>
              <span className="btn btn-warning btn-sm para mx-2">
                <i className="fas fa-eye text-white"></i>
              </span>
            </a>
          </Tooltip>
          <Tooltip title="Donate Now">
            <button
              className="btn btn-violet btn-sm mx-2 homebtnheight"
              onClick={() => donatenow(record)}
            >
              <span className="">Donate Now</span>
            </button>
          </Tooltip>
          {record.interest === null ? (
            <Tooltip title="Show interest">
              <Button
                className="btnnca btn-gold btn-sm mx-2 homebtnheight"
                onClick={() => showInterest(record)}
              >
                Show Interest
              </Button>
            </Tooltip>
          ) : (
            <Tag color="green" className="ms-2 p-1">
              Shown Interest
            </Tag>
          )}
        </div>
      ),
    },
  ];
  const ShowInterestformData = new FormData();

  const showInterest = (record) => {
    setRerendertable(record);
    setCauseId(record.cause.causeId);
    setIsdesModalOpen(true);
  };
  const donatenow = (record) => {
    const ngoDetails = {
      ngoId: record.cause.entityId,
      causeId: record.cause.causeId,
    };
    navigate("/Corp_Payment", {
      state: {
        viewdata: ngoDetails,
      },
    });
  };
  const handleOk = () => {
    navigate("/Corp_NGO");
  };
  const onsubmit = (value) => {
    setIsdesModalOpen(false);
    const corpRegisterInterest = {
      id: null,
      corpId: userCred.associatedEntity,
      ngoId: viewdata.viewdata.ngoProfile.id,
      causeId: causeId,
      interestRegDate: formatDate(new Date()),
      corpComments: value.reason,
      adminActionStartDate: null,
      adminComments: null,
      isDonationProvided: null,
      status: null,
      closedDate: null,
    };

    ShowInterestformData.append("userCred", JSON.stringify(userCred));
    ShowInterestformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    ShowInterestformData.append(
      "corpRegisterInterest",
      JSON.stringify(corpRegisterInterest)
    );

    UploadService.create(
      "corp/cause/registerInterest",
      ShowInterestformData
    ).then((response) => {
      if (response.data.httpStatus == "OK") {
        setIsModalOpen(true);
      }
    });
  };
  return (
    <>
      <>
        <div className="col m-3">
          <NavLink to="/Corp_NGO">
            <button className="btn btn-violet btn-sm ">
              <i className="fa fa-arrow-left"></i> Back
            </button>
          </NavLink>
        </div>
        <div className="col-md-12 row fontfamilynca  pt-2 px-4">
          <div className="col-sm-6">
            <h6 className="tableheading">Approved Cause:</h6>
          </div>
          <section className="content">
            {tablelength > 0 && (
              <div className="row">
                <div className="mt-2">
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    style={{
                      padding: "5px",
                    }}
                    onChange={handlePageChange}
                    bordered
                    pagination={false}
                    className="custom-table"
                    scroll={{
                      x: 1200,
                    }}
                  />
                  <Pagination
                    Current={page}
                    total={totalElements}
                    pageSize={size}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            )}
            {tablelength == 0 && (
              <div
                className="d-flex justify-content-center"
                style={{ height: "80vh" }}
              >
                <img src={Nodata} alt="Nodata" className="alignimagecenter" />
              </div>
            )}
          </section>
        </div>
      </>
      <Modal
        open={isdesModalOpen}
        footer={null}
        centered={true}
        onCancel={() => setIsdesModalOpen(false)}
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmit}>
          <p>Plese provide the Description.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Additional  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={isModalOpen}
        closeIcon={false}
        onOk={handleOk}
        centered={true}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>
          </div>,
        ]}
      >
        {" "}
        <div className="d-flex justify-content-center">
          <img
            src={require("./../../assets/icons/check-icon.png")}
            alt="sucess-icon"
            className="sucessiconimg"
          />
        </div>
        <div className="d-flex justify-content-center mt-3 text-center">
          Thanks for showing Interest for this cause,Our Admin Team will Contact
          you soon.
        </div>
        <div className="d-flex justify-content-center mt-2 b"> Thank you!</div>
      </Modal>
    </>
  );
}

export default CorpNGOCause;
