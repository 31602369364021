import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./shared/header/headersignin";
import Footer from "./shared/footer/footer";
import { useDispatch } from "react-redux";
import { setCauseData } from "../slices/causeSlice";
import UploadService from "../doaram/service/file-upload.service";
import { useParams } from "react-router-dom";
import verified from "../doaram/assets/icons/verified.png";
import Defaultimage1 from "../doaram/assets/da_img/blog_1.jpg";
import Defaultimage2 from "../doaram/assets/da_img/blog_2.jpg";
import Defaultimage3 from "../doaram/assets/da_img/blog_3.jpg";

function NGO_Microsite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image1name, setimage1name] = useState("");
  const [image2name, setimage2name] = useState("");
  const [image3name, setimage3name] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [micrositeProfile, setMicrositeProfile] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [causeList, setCauseList] = useState([]);
  const causelistformData = new FormData();
  let { ngoname, ngoid } = useParams();
  let tablelength = dataSource.length;

  useEffect(() => {
    const micrositeuserCred = {
      userId: "",
      userName: "",
      password: "",
      termsConditions: "Y",
      systemUpdateCommunication: "",
      userType: "NGO",
      userRole: "",
      associatedEntity: ngoid,
      status: "",
      comments: "",
      passwordSetTime: "",
      numLogin: 0,
      lastLoginTime: "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };
    const microSiteformData = new FormData();
    microSiteformData.append("userCred", JSON.stringify(micrositeuserCred));
    UploadService.create(
      "common/public/noauth/getNGOMicroSiteDetails",
      microSiteformData
    ).then((response) => {
      setMicrositeProfile(response.data.data);
    });
  }, [ngoid]);

  useEffect(() => {
    if (micrositeProfile) {
      const individualimage =
        micrositeProfile?.ngoProfile?.micrositeImages.split(",");
      setimage1name(individualimage[0]);
      setimage2name(individualimage[1]);
      setimage3name(individualimage[2]);

      causelistformData.append("page", page - 1);
      causelistformData.append("size", size);
      causelistformData.append("entityId", micrositeProfile?.ngoProfile?.id);

      UploadService.create(
        "common/public/noauth/getAllCausesOfNGO",
        causelistformData
      ).then((response) => {
        setDataSource(response.data.data.causes.content);
      });
    }
  }, [micrositeProfile, page, size,ngoid]);

  const micrositeimage1Url = image1name
    ? `https://www.doaram.com/ngo/${micrositeProfile?.ngoProfile?.id}/microSiteImages/${image1name}`
    : Defaultimage1;
  const micrositeimage2Url = image2name
    ? `https://www.doaram.com/ngo/${micrositeProfile?.ngoProfile?.id}/microSiteImages/${image2name}`
    : Defaultimage2;
  const micrositeimage3Url = image3name
    ? `https://www.doaram.com/ngo/${micrositeProfile?.ngoProfile?.id}/microSiteImages/${image3name}`
    : Defaultimage3;

  const causepagedata = new FormData();

  const ViewCausePage = (causeid, causename) => {
    const causeSlug = causename.toLowerCase().replace(/\s+/g, "-");
    navigate(`/corp/cause/${causeSlug}/${causeid}`);
  };
  return (
    <>
      {micrositeProfile ? (
        <div style={{ backgroundColor: "#eeeeee" }}>
          <Header />
          <div className="container mt-5 pt-5">
            <div className="row g-5 mt-5">
              <div className="container">
                <div className="row g-5">
                  <div className="col-lg-6 p-5 wow fadeIn  " data-wow-delay="0.5s">
                    <h1 className="fw-web txt-warning mb-2 mt-2">
                      {micrositeProfile.ngoProfile.name.toUpperCase()}
                    </h1>
                    <span className="bg-white ft-10 p-1 rounded-pill text-dark">
                      <img src={verified} className="img-fluid" width="20px" />
                      &nbsp;Verified
                    </span>
                    <h6 className="fw-web txt-warning mt-4 mb-2 subheading">
                      About
                    </h6>
                    <p className="mb-4">{micrositeProfile.ngoProfile.about}</p>
                    <h5 className="fw-web txt-warning mt-4 mb-2 subheading">
                      Vision
                    </h5>
                    <p className="mb-4">{micrositeProfile.ngoProfile.vision}</p>
                  </div>
                  <div className="col-lg-6 p-5  ">
                    <div className="row  ">
                      <div
                        id="carouselExampleIndicators"
                        className="carousel slide"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-indicators">
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"
                          ></button>
                        </div>
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <img
                              src={micrositeimage1Url}
                              className="d-block w-100 img-fluid websiteimage"
                              alt="..."
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              src={micrositeimage2Url}
                              className="d-block w-100 img-fluid websiteimage"
                              alt="..."
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              src={micrositeimage3Url}
                              className="d-block w-100 img-fluid websiteimage"
                              alt="..."
                            />
                          </div>
                        </div>
                        <button
                          className="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Categories--> */}
          <div
            className="container-fluid mb-3 webbody pt-5"
            style={{ backgroundColor: "#2D1621" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-4 d-flex align-items-center mb-5 mb-lg-0 justify-content-center">
                  <div className="service-block p-4 color-bg text-center">
                    <div className="service-icon text-center">
                      <i className="fa fa-crosshairs"></i>
                    </div>
                    <h6 className="fw-web  mb-2 subheading">Category</h6>
                    <span>{micrositeProfile.ngoProfile.category}</span>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 justify-content-center">
                  <div className="service-block p-4 color-bg text-center">
                    <div className="service-icon text-center">
                      <i className="fa fa-user-circle"></i>
                    </div>
                    <h6 className="fw-web   mb-2 subheading">Membership</h6>
                    <span>
                      {micrositeProfile.ngoProfile.membership.split("_")[1]}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0 justify-content-center">
                  <div className="service-block p-4 color-bg text-center">
                    <div className="service-icon text-center">
                      <i className="fa fa-building"></i>
                    </div>
                    <h6 className="fw-web mb-2 subheading">City</h6>
                    <span>{micrositeProfile.contactInfo.city}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Causes--> */}
          {tablelength > 0 && (
            <div className="container-fluid mb-3 webbody pt-5">
              <div className="container">
                <h1 className="text-center " style={{ Color: "#E9BF53" }}>
                  Causes
                </h1>
                <p className="text-center" style={{ Color: "#F6F6F6" }}>
                  Here are the causes that {micrositeProfile.ngoProfile.name} is
                  supporting
                </p>
                <div className="row">
                  {dataSource.map((cause, index) => (
                    <div
                      key={index}
                      className="col-sm-12 col-md-6 col-lg-4 mb-4"
                      onClick={() => ViewCausePage(cause.causeId, cause.title)}
                    >
                      <div className="custom-card click-col">
                        <img
                          src={`https://www.doaram.com/ngo/${
                            cause.entityId
                          }/cause/${cause.causeId}/${
                            cause.images.split(",")[0]
                          }`}
                          alt={cause.title}
                        />
                        <div className="card-content">
                          <h6 className="text-white mb-2 subheading font-weight-bold">
                            {cause.title.toUpperCase()}
                          </h6>
                          <p className="causecardtxt">
                            {" "}
                            {cause.description.length > 50
                              ? `${cause.description.slice(0, 50)}...`
                              : cause.description}
                          </p>
                          <div className="learn-more">
                            Learn More <i className="fas fa-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* fOOTER */}
          <div
            className="container-fluid mb-3 webbody pt-5"
            style={{ backgroundColor: "#D9D9D9" }}
          >
            <div className="container ">
              <div className="row g-5 ">
                <div
                  className="col-lg-6 wow fadeIn text-start  "
                  data-wow-delay="0.5s"
                >
                  <h4
                    className="fw-web  mb-2 subheading"
                    style={{ Color: "#2D1621" }}
                  >
                    Address
                  </h4>
                  <p style={{ Color: "#666666" }}>
                    <i className="fa fa-map-marker"></i>{" "}
                    {micrositeProfile.contactInfo.addressLine1}
                  </p>
                </div>
                <div
                  className="col-lg-6  wow fadeIn text-start  "
                  data-wow-delay="0.5s"
                >
                  <h4
                    className="fw-web  mb-2 subheading"
                    style={{ Color: "#2D1621" }}
                  >
                    Contact details
                  </h4>
                  <div className="">
                    <p style={{ Color: "#666666 " }}>
                      <i className="fa fa-envelope"></i>{" "}
                      {micrositeProfile.contactInfo.email}
                    </p>
                    <p style={{ Color: " #666666" }}>
                      <i className="fa fa-phone"></i>
                      {micrositeProfile.contactInfo.phone}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        "Loading"
      )}
    </>
  );
}

export default NGO_Microsite;
