import { Table, Modal, Input, Button, Form, Tooltip, Tabs } from "antd";
import { useNavigate, NavLink } from "react-router-dom";
import UploadService from "../../service/file-upload.service";

function Admin_MembershipOrderstatus() {
  const dataSource = [
    {
      key: "1",
      orderId: "ngo_mu_order_124",
      userId: "doaramtestdev@gmail.com",
      membershipLevel: "Gold",
      membership_Fee: "Annual",
      orderStatus: "PENDING",
      paymentStatus: "COMPLETED",
    }
  ];
  const columns = [
    {
      title: "OrderId",
      dataIndex: "orderId",
    },
    {
      title: "UserId",
      dataIndex: "userId",
    },
    {
      title: "MembershipLevel",
      dataIndex: "membershipLevel",
    },
    {
      title: "Membership_Fee",
      dataIndex: "membership_Fee",
    },
    {
      title: "OrderStatus",
      dataIndex: "orderStatus",
    },
    {
      title: "PaymentStatus",
      dataIndex: "paymentStatus",
    },
  ];
  return (
    <>
      <div className="col-sm-8 row m-1">
        <div className="col">
          <NavLink to="/NGOMembership">
          <button className="btn btn-violet btn-sm">
                      <i className="fa fa-arrow-left"></i> Back
                    </button>
          </NavLink>
        </div>
      </div>
      <div className="col-md-12 row fontfamilynca pt-2 px-4" >
        <div className="col-sm-6">
          <h6 className="tableheading">Order Status:</h6>
        </div>
        <section className="content   ">
          <div className="row">
            <div className="mt-3 ">
              <Table
                columns={columns}
                dataSource={dataSource}
                style={{ padding: "5px" }}
                bordered
                scroll={{
                  x: 1200
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Admin_MembershipOrderstatus;
