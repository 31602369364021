import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Modal,
  Input,
  Button,
  Form,
  Tooltip,
  Pagination,
  Tag,
  Tabs,
} from "antd";
import Nodata from "../../assets/icons/Nocause.png";
import $ from "jquery";
import UploadService from "../../service/file-upload.service";

function Corp_Cause() {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(7);
  const [totalElements, setTotalElements] = useState();
  const [dataSource, setDataSource] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tablelength, setTablelength] = useState();
  const [reRendertable, setRerendertable] = useState();

  const [otherpage, setOtherPage] = useState(1);
  const [othersize, setOtherSize] = useState(7);
  const [othertotalElements, setOtherTotalElements] = useState();
  const [otherdataSource, setOtherDataSource] = useState();
  const [othertablelength, setOtherTablelength] = useState();
  const [isdesModalOpen, setIsdesModalOpen] = useState(false);
  const [causeId, setCauseId] = useState();
  const [entityId, setEntityId] = useState();
  const [rerender, setRerender] = useState();
  const [activeTab, setActiveTab] = useState("1");

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleOtherPageChange = (otherpage) => {
    setOtherPage(otherpage);
  };
  const PrefCauseformData = new FormData();
  PrefCauseformData.append("userCred", JSON.stringify(userCred));
  PrefCauseformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  PrefCauseformData.append("prefFlag", "PREF_MEMBER_AND_CATEGORY");
  PrefCauseformData.append("page", page - 1);
  PrefCauseformData.append("size", size);

  const OtherCauseformData = new FormData();
  OtherCauseformData.append("userCred", JSON.stringify(userCred));
  OtherCauseformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  OtherCauseformData.append("prefFlag", "OTHER_MEMBER_AND_PREF_CATEGORY");
  OtherCauseformData.append("page", otherpage - 1);
  OtherCauseformData.append("size", othersize);

  useEffect(() => {
    UploadService.create("corp/get/cause/preference", PrefCauseformData).then(
      (response) => {
        setDataSource(response.data.data.causeInterestList);
        setTotalElements(response.data.data.totalElements);
        setTablelength(response.data.data.totalElements);
      }
    );
    UploadService.create("corp/get/cause/preference", OtherCauseformData).then(
      (response) => {
        setOtherDataSource(response.data.data.causeInterestList);
        setOtherTotalElements(response.data.data.totalElements);
        setOtherTablelength(response.data.data.totalElements);
      }
    );
  }, [page, otherpage, activeTab, reRendertable]);

  const columns = [
    {
      title: "NGO Name",
      dataIndex: ["cause", "name"],
      width: 120,
    },
    {
      title: "Cause Category",
      dataIndex: ["cause", "category"],
      width: 30,
    },
    // {
    //   title: "Location",
    //   width: 90,
    //   render: (text, record) => (
    //     <span>{`${record.cause.city}, ${record.cause.state}`}</span>
    //   ),
    // },

    {
      title: "EndDate",
      dataIndex: ["cause", "end_date"],
      width: 10,
      render: (text) => {
        const datePart = text.substr(0, 10);
        return datePart;
      },
    },

    {
      title: "Membership",
      dataIndex: ["cause", "membership"],
      width: 10,
      render: (text) => {
        if (text === "NGO_APPLICANT") {
          return <Tag color="#e8c3cc">APPLICANT</Tag>;
        }
        if (text === "NGO_BRONZE") {
          return <Tag color="#cd7f32">BRONZE</Tag>;
        }
        if (text === "NGO_SILVER") {
          return <Tag color="#6a737b">SILVER</Tag>;
        }
        if (text === "NGO_GOLD") {
          return <Tag color="#d2af26">GOLD</Tag>;
        }
        if (text === "NGO_PLATINUM") {
          return <Tag color="#008eaa">PLATINUM</Tag>;
        }
        return text;
      },
    },
    {
      title: "Action",
      key: "operation",
      width: 200,
      render: (record) => (
        <div className="d-flex">
          <Tooltip title="View Profile">
            <a onClick={() => viewProfile(record)}>
              <span className="btn  btn-info btn-sm para ">
                <i className="fas fa fa-globe text-white"></i>
              </span>
            </a>
          </Tooltip>
          <Tooltip title="View Cause">
            <a onClick={() => viewCause(record)}>
              <span className="btn btn-warning btn-sm para ms-1  ">
                <i className="fas fa-eye text-white"></i>
              </span>
            </a>
          </Tooltip>

          <Tooltip title="Donate Now">
            <button
              className=" btn btn-violet btn-sm ms-1 homebtnheight"
              onClick={() => donatenow(record)}
            >
              <span className="">Donate Now</span>
            </button>
          </Tooltip>

          {record.interest === null ? (
            <Tooltip title="Show interest">
              <Button
                className="btnnca btn-gold btn-sm ms-1 homebtnheight"
                onClick={() => showinterest(record)}
              >
                Show Interest
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Already Shown interest">
              <Tag color="green" className="ms-1 p-1">
                Shown Interest
              </Tag>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const viewCause = (record) => {
    const causeSlug = record.cause.name.toLowerCase().replace(/\s+/g, "-");
    const url = `/corp/cause/${causeSlug}/${record.cause.causeId}`;
    window.open(url, "_blank");
  };
  const viewProfile = (record) => {
    const ngoSlug = record.cause.name.toLowerCase().replace(/\s+/g, "-");
    const url = `/corp/ngo/${ngoSlug}/${record.cause.entityId}`;
    window.open(url, "_blank");
  };
  const donatenow = (record) => {
    const ngoDetails = {
      ngoId: record.cause.entityId,
      causeId: record.cause.causeId,
    };
    navigate("/Corp_Payment", {
      state: {
        viewdata: ngoDetails,
      },
    });
  };
  const showinterest = (record) => {
    setCauseId(record.cause.causeId);
    setEntityId(record.cause.entityId);
    setIsdesModalOpen(true);
  };
  const ShowInterestformData = new FormData();
  function formatDate(date) {
    const d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-");
  }
  const onsubmit = (value) => {
    setIsdesModalOpen(false);
    const corpRegisterInterest = {
      id: null,
      corpId: userCred.associatedEntity,
      ngoId: entityId,
      causeId: causeId,
      interestRegDate: formatDate(new Date()),
      corpComments: value.reason,
      adminActionStartDate: null,
      adminComments: null,
      isDonationProvided: null,
      status: null,
      closedDate: null,
    };

    ShowInterestformData.append("userCred", JSON.stringify(userCred));
    ShowInterestformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    ShowInterestformData.append(
      "corpRegisterInterest",
      JSON.stringify(corpRegisterInterest)
    );

    UploadService.create(
      "corp/cause/registerInterest",
      ShowInterestformData
    ).then((response) => {
      if (response.data.httpStatus == "OK") {
        setIsModalOpen(true);
        setRerendertable(response.data.data);
      }
    });
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <>
        <div className="col-md-12 row fontfamilynca  pt-2 px-4">
          <div className="col-sm-6">
            <h6 className="tableheading">Approved Cause:</h6>
          </div>
          <Tabs
            defaultActiveKey={activeTab}
            centered
            type="card"
            large
            items={[
              {
                label: " Preferred Causes",
                key: "1",
                children: (
                  <section className="content">
                    {tablelength > 0 && (
                      <div className="row">
                        <div className="mt-2">
                          <Table
                            columns={columns}
                            dataSource={dataSource}
                            style={{
                              padding: "5px",
                            }}
                            onChange={handlePageChange}
                            bordered
                            pagination={false}
                            className="custom-table"
                            scroll={{
                              x: 1200,
                            }}
                          />
                          <Pagination
                            Current={page}
                            total={totalElements}
                            pageSize={size}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    )}
                    {tablelength == 0 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "80vh" }}
                      >
                        <img
                          src={Nodata}
                          alt="Nodata"
                          className="alignimagecenter"
                        />
                      </div>
                    )}
                  </section>
                ),
              },
              {
                label: " Other Causes",
                key: "2",
                children: (
                  <section className="content">
                    {othertablelength > 0 && (
                      <div className="row">
                        <div className="mt-2">
                          <Table
                            columns={columns}
                            dataSource={otherdataSource}
                            style={{
                              padding: "5px",
                            }}
                            onChange={handlePageChange}
                            bordered
                            pagination={false}
                            className="custom-table"
                            scroll={{
                              x: 1100,
                            }}
                          />
                          <Pagination
                            Current={otherpage}
                            total={othertotalElements}
                            pageSize={othersize}
                            onChange={handleOtherPageChange}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    )}
                    {othertablelength == 0 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "80vh" }}
                      >
                        <img
                          src={Nodata}
                          alt="Nodata"
                          className="alignimagecenter"
                        />
                      </div>
                    )}{" "}
                  </section>
                ),
              },
            ]}
          />
        </div>
      </>

      <Modal
        open={isdesModalOpen}
        footer={null}
        centered={true}
        onCancel={() => setIsdesModalOpen(false)}
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmit}>
          <p>Plese provide the Description.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Additional  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={isModalOpen}
        closeIcon={false}
        onOk={handleOk}
        centered={true}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>
          </div>,
        ]}
      >
        {" "}
        <div className="d-flex justify-content-center">
          <img
            src={require("./../../assets/icons/check-icon.png")}
            alt="sucess-icon"
            className="sucessiconimg"
          />
        </div>
        <div className="d-flex justify-content-center mt-3 text-center">
          Thanks for showing Interest for this cause,Our Admin Team will Contact
          you soon.
        </div>
        <div className="d-flex justify-content-center mt-2 b"> Thank you!</div>
      </Modal>
    </>
  );
}

export default Corp_Cause;
