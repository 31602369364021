import React, { useState, useEffect } from "react";
import Header from "./shared/header/headersignin";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./shared/footer/footer";
import { useParams } from "react-router-dom";
import UploadService from "../doaram/service/file-upload.service";
import verified from "../doaram/assets/icons/verified.png";
import { Modal, Input, Button, Form, message } from "antd";
import { Progress } from "antd";
import $ from "jquery";

function Cause_Microsite() {
  let { causename, causeid } = useParams();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [causeData, setCauseData] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [percentage, setpercentage] = useState();
  const [isdesModalOpen, setIsdesModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const causelistformData = new FormData();
  useEffect(() => {
    const causepagedata = new FormData();
    causepagedata.append("causeId", causeid);
    UploadService.create(
      "common/public/noauth/getCauseMicroSiteDetails",
      causepagedata
    ).then((response) => {
      setCauseData(response.data.data);
    });
  }, [causeid]);
  useEffect(() => {
    if (causeData) {
      const individualimage = causeData.causes.images.split(",");
      causelistformData.append("page", page - 1);
      causelistformData.append("size", size);
      causelistformData.append("entityId", causeData.causes.entityId);
      UploadService.create(
        "common/public/noauth/getAllCausesOfNGO",
        causelistformData
      ).then((response) => {
        setDataSource(response.data.data.causes.content);
      });

      const receivedAmount = causeData.causes.receivedAmount;
      const goalAmount = causeData.causes.goalAmount;
      setpercentage((receivedAmount / goalAmount) * 100);
    }
  }, [causeData, page, size, causeid]);

  const ViewCausePage = (causeid, causename) => {
    const causeSlug = causename.toLowerCase().replace(/\s+/g, "-");
    navigate(`/corp/cause/${causeSlug}/${causeid}`);
  };

  const showinterest = () => {
    setIsdesModalOpen(true);
  };
  function formatDate(date) {
    const d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-");
  }

  const onsubmit = (value) => {
    setIsdesModalOpen(false);
    const corpRegisterInterest = {
      id: null,
      corpId: userCred.associatedEntity,
      ngoId: causeData.causes.entityId,
      causeId: causeData.causes.causeId,
      interestRegDate: formatDate(new Date()),
      corpComments: value.reason,
      adminActionStartDate: null,
      adminComments: null,
      isDonationProvided: null,
      status: null,
      closedDate: null,
    };

    const ShowInterestformData = new FormData();
    ShowInterestformData.append("userCred", JSON.stringify(userCred));
    ShowInterestformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    ShowInterestformData.append(
      "corpRegisterInterest",
      JSON.stringify(corpRegisterInterest)
    );

    UploadService.create(
      "corp/cause/registerInterest",
      ShowInterestformData
    ).then((response) => {
      if (response.data.httpStatus == "OK") {
        setIsModalOpen(true);
      }
    });
  };
  return (
    <>
      {causeData ? (
        <>
         
          <div style={{ backgroundColor: "#eeeeee" }}>
            <Header />

            <div className="container mt-5 pt-5">
              <div className="row g-5 mt-5">
                <div className="container">
                  <div className="row g-5">
                    <div
                      className="col-lg-6 p-5 wow fadeIn  "
                      data-wow-delay="0.5s"
                    >
                      <h1 className="fw-web txt-warning mb-2 mt-2">
                        {causeData.causes.title.toUpperCase()}
                      </h1>
                      <span className="bg-white ft-10 p-1 rounded-pill text-dark">
                        <img src={verified} className="img-fluid" width="20px" />
                        &nbsp;Verified
                      </span>
                      <h6 className="fw-web txt-warning mt-4 mb-2 subheading">
                        About
                      </h6>
                      <p className="mb-4">{causeData.causes.description}</p>
                      <h6 className="fw-web txt-warning mt-4 mb-2 subheading">
                        Category
                      </h6>
                      <p className="mb-4">{causeData.causes.category}</p>
                      {/* <button
                        className="btn btn-outline-secondary btn-sm"
                        onClick={showinterest}
                      >
                        Show Interest
                      </button> */}
                    </div>
                    <div className="col-lg-6 p-5 ">
                      <div className="row  ">
                        <div
                          id="carouselExampleIndicators"
                          className="carousel slide"
                          data-bs-ride="carousel"
                        >
                          <div className="carousel-indicators">
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="0"
                              className="active"
                              aria-current="true"
                              aria-label="Slide 1"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="1"
                              aria-label="Slide 2"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="2"
                              aria-label="Slide 3"
                            ></button>
                          </div>
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                src={`https://www.doaram.com/ngo/${
                                  causeData.causes.entityId
                                }/cause/${causeData.causes.causeId}/${
                                  causeData.causes.images.split(",")[0]
                                }`}
                                className="d-block w-100 img-fluid websiteimage"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={`https://www.doaram.com/ngo/${
                                  causeData.causes.entityId
                                }/cause/${causeData.causes.causeId}/${
                                  causeData.causes.images.split(",")[1]
                                }`}
                                className="d-block w-100 img-fluid websiteimage"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={`https://www.doaram.com/ngo/${
                                  causeData.causes.entityId
                                }/cause/${causeData.causes.causeId}/${
                                  causeData.causes.images.split(",")[2]
                                }`}
                                className="d-block w-100 img-fluid websiteimage"
                                alt="..."
                              />
                            </div>
                          </div>
                          <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="container-fluid   mt-3"
              style={{ backgroundColor: " #D9D9D9" }}
            >
              <div className="container ">
                <div className="row g-5 ">
                  <div
                    className="col-lg-4 p-4 wow fadeIn text-start  "
                    data-wow-delay="0.5s"
                  >
                    <h4
                      className="fw-web  mb-2 subheading "
                      style={{ Color: "#2D1621" }}
                    >
                      Address
                    </h4>
                    <p style={{ Color: "#666666 " }}>
                      <i className="fa fa-map-marker"></i>{" "}
                      {causeData.contactInfo.addressLine1}
                    </p>
                  </div>
                  <div
                    className="col-lg-4 p-4 wow fadeIn text-start  "
                    data-wow-delay="0.5s"
                  >
                    <h4
                      className="fw-web subheading mb-2 "
                      style={{ Color: "#2D1621" }}
                    >
                      Contact details
                    </h4>
                    <div className="">
                      <p style={{ Color: "#666666 " }}>
                        <i className="fa fa-envelope"></i>{" "}
                        {causeData.contactInfo.email}
                      </p>
                      <p style={{ Color: "#666666 " }}>
                        <i className="fa fa-phone"></i>{" "}
                        {causeData.contactInfo.phone}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 p-4 wow fadeIn text-start  "
                    data-wow-delay="0.5s"
                  >
                    <h4
                      className="fw-web subheading  mb-2 "
                      style={{ Color: "#2D1621" }}
                    >
                      Goal
                    </h4>
                    <div className="row" style={{ Color: "#666666" }}>
                      <div className="col-md-6">Collection</div>
                      <div className="col-md-6">
                        ₹{causeData.causes.receivedAmount}/
                        {causeData.causes.goalAmount}
                      </div>
                      <Progress percent={percentage} />
                    </div>
                    <div className="row mt-2" style={{ Color: "#666666" }}>
                      <div className="col-md-6">Duration</div>
                      <div className="col-md-6">
                        {causeData.causes.startDate}-{causeData.causes.endDate}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid mb-3 webbody pt-5">
              <div className="container">
                <h1 className="text-center " style={{ Color: "#E9BF53" }}>
                  Other Causes from this NGO
                </h1>
                <p className="text-center" style={{ Color: "#F6F6F6" }}>
                  Here are the other causes of this NGO
                </p>
                <div className="row">
                  {dataSource.map((cause, index) => (
                    <div
                      key={index}
                      className="col-sm-12 col-md-6 col-lg-4 mb-4"
                      onClick={() => ViewCausePage(cause.causeId, cause.title)}
                    >
                      <div className="custom-card click-col">
                        <img
                          src={`https://www.doaram.com/ngo/${
                            cause.entityId
                          }/cause/${cause.causeId}/${
                            cause.images.split(",")[0]
                          }`}
                          alt={cause.title}
                        />
                        <div className="card-content">
                          <h6 className="text-white mb-2 subheading font-weight-bold">
                            {cause.title.toUpperCase()}
                          </h6>
                          <p className="causecardtxt">
                            {" "}
                            {cause.description.length > 50
                              ? `${cause.description.slice(0, 50)}...`
                              : cause.description}
                          </p>
                          Learn More <i className="fas fa-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </>
      ) : (
        "LOADING"
      )}
      <Modal
        open={isdesModalOpen}
        footer={null}
        centered={true}
        onCancel={() => setIsdesModalOpen(false)}
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmit}>
          <p>Plese provide the Description.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Additional  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={isModalOpen}
        closeIcon={false}
        onOk={() => {
          setIsModalOpen(false);
        }}
        centered={true}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button
              key="ok"
              type="primary"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              OK
            </Button>
          </div>,
        ]}
      >
        {" "}
        <div className="d-flex justify-content-center">
          <img
            src={require("../doaram/assets/icons/check-icon.png")}
            alt="sucess-icon"
            className="sucessiconimg"
          />
        </div>
        <div className="d-flex justify-content-center mt-3 text-center">
          Thanks for showing Interest for this cause,Our Admin Team will Contact
          you soon.
        </div>
        <div className="d-flex justify-content-center mt-2 b"> Thank you!</div>
      </Modal>
    </>
  );
}

export default Cause_Microsite;
