import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./doaram_web/home.jsx";
import About from "./doaram_web/about.jsx";
import Corporate from "./doaram_web/corporatesolution.jsx";
import Individuals from "./doaram_web/individuals.jsx";
import Contact from "./doaram_web/contact.jsx";
import Blog from "./doaram_web/blog.jsx";
import FAQ from "./doaram_web/faq.jsx";
import Insight from "./doaram_web/insight.jsx";
import Listedngo from "./doaram_web/listedngo.jsx";
import Policy from "./doaram_web/shared/policy&terms/policy.jsx";
import Terms from "./doaram_web/shared/policy&terms/terms.jsx";
import ScrollToTop from "./doaram_web/scrolltotop.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import NGO_Login from "./doaram/components/ngo/NGO_Login";
import Corp_Login from "./doaram/components/corp/Corp_Login";
import Admin_Login from "./doaram/components/admin/Admin_Login";
import Signup from "./doaram/components/ngo/Signup";
import Corp_Signup from "./doaram/components/corp/Corp_Signup";
import Corp_SignUpSelector from "./doaram/components/corp/Corp_SignUpSelector";
import NGO_Cause from "./doaram/components/ngo/NGO_Cause";
import CauseDetails from "./doaram/components/ngo/CauseDetails";
import NGO_UpdateCause from "./doaram/components/ngo/NGO_UpdateCause.jsx";
import NGO_Profile from "./doaram/components/ngo/NGO_Profile";
import NGO_Plan from "./doaram/components/ngo/NGO_Plan";
import Error404 from "./doaram/components/ngo/Error404";
import ForgotPassword from "./doaram/components/shared/ForgotPassword";
import Corp_NGOs from "./doaram/components/ngo/Corp_NGOs";
import Notification from "./doaram/components/ngo/Notification";
import TermsConditions from "./doaram/components/ngo/TermsConditions";
import Admin_Cause from "./doaram/components/admin/Admin_Cause";
import Admin_Profile from "./doaram/components/admin/Admin_Profile";
import Admin_NGO from "./doaram/components/admin/Admin_NGO";
import Corp_NGO from "./doaram/components/corp/Corp_NGO";
import Corp_Profile from "./doaram/components/corp/Corp_Profile";
import NGO_Dashboard from "./doaram/components/ngo/NGO_Dashboard";
import Corp_Dashboard from "./doaram/components/corp/Corp_Dashboard";
import Admin_Dashboard from "./doaram/components/admin/Admin_Dashboard";
import SignUpSelectors from "./doaram/components/ngo/SignupSelectors";
import VerifyEmail from "./doaram/components/ngo/VerifyEmail";
import AccountVerify from "./doaram/components/ngo/AccountVerify";
import Admin_ViewCause from "./doaram/components/admin/Admin_ViewCause";
import Admin_ViewProfile from "./doaram/components/admin/Admin_ViewProfile";
import ResetPassword from "./doaram/components/shared/ResetPassword";
import Admin_ForgotPassword from "./doaram/components/admin/Admin_ForgotPassword";
import Admin_ResetPassword from "./doaram/components/admin/Admin_ResetPassword";
import BlogDetails1 from "./doaram_web/blog1.jsx";
import BlogDetails2 from "./doaram_web/blog2";
import BlogDetails3 from "./doaram_web/blog3";
import BlogDetails4 from "./doaram_web/blog4";
import "../src/css/style.css";
import SideMenu from "./doaram/components/shared/SideMenu";
import SideMenuadmin from "./doaram/components/shared/SideMenuadmin";
import SideMenucorp from "./doaram/components/shared/SideMenucorp";
import SignedinUpgrade from "./doaram/components/ngo/SignedinUpgrade.jsx";
import Admin_MembershipHistory from "./doaram/components/admin/Admin_MembershipHistory.jsx";
import Admin_MembershipOrderstatus from "./doaram/components/admin/Admin_MembershipOrderstatus.jsx";
import Admin_MembershipProfile from "./doaram/components/admin/Admin_MembershipProfile.jsx";
import Admin_NGOMembership from "./doaram/components/admin/Admin_NGOMembership.jsx";
import Individual_Login from "./doaram/components/individual/Individual_Login.jsx";
import ListedCause from "./doaram_web/listedCause.jsx";
import Additionalinfo from "./doaram/components/ngo/additionalinfo.jsx";
import IndividualDonation from "./doaram/components/individual/Individual_UserDetails.jsx";
import Admin_Donation from "./doaram/components/admin/Admin_Donation.jsx";
import NGO_Donation from "./doaram/components/ngo/NGO_Donation.jsx";
import NGO_Membershipstatus from "./doaram/components/ngo/NGO_Membershipstatus.jsx";
import CauseAdditionalinfo from "./doaram/components/ngo/NGO_CauseAddinfo.jsx";
import Admin_PaymentStatus from "./doaram/components/admin/Admin_PaymentStatus.jsx";
import IndividualSignUpSelectors from "./doaram/components/individual/Individual_SignupSeletor.jsx";
import IndividualSignup from "./doaram/components/individual/Signup.jsx";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store.js";
import Admin_Corp from "./doaram/components/admin/Admin_Corp.jsx";
import CorpAccountVerify from "./doaram/components/corp/Corp_AccountVerify.jsx";
import Admin_ViewCorp from "./doaram/components/admin/Admin_ViewCorp.jsx";
import Corp_Addinfo from "./doaram/components/corp/Corp_Addinfo.jsx";
import CorpNGOCause from "./doaram/components/corp/Corp_NGOCause.jsx";
import Corp_AccountVerify from "./doaram/components/corp/Corp_SignupSucess.jsx";
import Cause_Microsite from "./doaram_web/causewebsite.jsx";
import Admin_CorpIntrest from "./doaram/components/admin/Admin_ShownIntrest.jsx";
import Admin_CorpIntrestedprofile from "./doaram/components/admin/Admin_ProfileIntrested.jsx";
import Admin_CorpList from "./doaram/components/admin/Admin_CorpIntrestedlist.jsx";
import PublicNGO_Microsite from "./doaram_web/publicngowebsite.jsx";
import PublicCause_Microsite from "./doaram_web/Publiccausewebsite.jsx";
import NGO_Microsite from "./doaram_web/ngowebsite.jsx";
import Corp_Cause from "./doaram/components/corp/Corp_Cause.jsx";
import Corp_Payments from "./doaram/components/corp/Corp_Payment.jsx";
import Admin_CorpDonation from "./doaram/components/admin/Admin_CorpDonation.jsx";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {/* ....................Public Routes.................. */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/corporate" element={<Corporate />} />
            <Route path="/individuals" element={<Individuals />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/blog" element={<Blog />} />
            <Route
              path="/blog/a-powerful-medium-to-create-public-awareness-is-through-street-play"
              element={<BlogDetails1 />}
            />
            <Route
              path="/blog/moral-science-a-bygone-subject-in-schools-should-be-made-mandatory"
              element={<BlogDetails2 />}
            />
            <Route
              path="/blog/self-help-group-the-role-of-ngos-in-promoting-shgs-and-its-scope"
              element={<BlogDetails3 />}
            />
            <Route
              path="/blog/foot-binding-was-a-symbol-of-status-modesty-and-moral-virtue"
              element={<BlogDetails4 />}
            />
            <Route
              path="/ngo/:ngoname/:ngoid"
              element={<PublicNGO_Microsite />}
            />
            <Route
              path="/cause/:causename/:causeid"
              element={<PublicCause_Microsite />}
            />
            <Route
              path="corp/ngo/:ngoname/:ngoid"
              element={<NGO_Microsite />}
            />
            <Route
              path="corp/cause/:causename/:causeid"
              element={<Cause_Microsite />}
            />
            <Route path="/listedngo" element={<Listedngo />} />
            {/* <Route path="/registeredngo" element={<Registeredngo />} /> */}
            <Route path="/insight" element={<Insight />} />
            <Route path="/cause" element={<ListedCause />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/terms" element={<Terms />} />
            {/* ----------------------NGO---------------------------- */}
            <Route path="/ngosignin" element={<NGO_Login />} />
            <Route path="/corpsignin" element={<Corp_Login />} />
            <Route path="/adminsignin" element={<Admin_Login />} />
            <Route path="/individualsignin" element={<Individual_Login />} />
            <Route
              path="/individual/causeDonation"
              element={<IndividualDonation />}
            />
            <Route
              path="/individualsignupselector"
              element={<IndividualSignUpSelectors />}
            />
            <Route path="/individualsignup" element={<IndividualSignup />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/Corp_email" element={<Corp_AccountVerify />} />
            <Route path="/corpsignup" element={<Corp_Signup />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route
              path="/adminforgotPassword"
              element={<Admin_ForgotPassword />}
            />
            <Route
              path="/adminresetPassword"
              element={<Admin_ResetPassword />}
            />
            <Route path="/SignUpSelector" element={<SignUpSelectors />} />
            {/* <Route path="/ngoprofiles" element={<NGO_Profiles />} /> */}
            <Route
              path="/corpSignUpSelector"
              element={<Corp_SignUpSelector />}
            />
            {/* ...............................Protected Routes.................. */}
            \{" "}
            <Route
              path="/ngoDashboard"
              element={
                <SideMenu>
                  <NGO_Dashboard />
                </SideMenu>
              }
            />
            <Route
              path="/NGO_Cause"
              element={
                <SideMenu>
                  <NGO_Cause />
                </SideMenu>
              }
            />
            <Route
              path="/Corp_NGOs"
              element={
                <SideMenu>
                  <Corp_NGOs />
                </SideMenu>
              }
            />
            <Route
              path="/CauseDetails"
              element={
                <SideMenu>
                  <CauseDetails />
                </SideMenu>
              }
            />
            <Route
              path="/ngocauseDonation"
              element={
                <SideMenu>
                  <NGO_Donation />
                </SideMenu>
              }
            />
            <Route
              path="/ngomembershipstatus"
              element={
                <SideMenu>
                  <NGO_Membershipstatus />
                </SideMenu>
              }
            />
            <Route
              path="/UpdateCause"
              element={
                <SideMenu>
                  <NGO_UpdateCause />
                </SideMenu>
              }
            />
            <Route
              path="/NGO_Profile"
              element={
                <SideMenu>
                  <NGO_Profile />
                </SideMenu>
              }
            />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/Upgrade" element={<NGO_Plan />} />
            <Route
              path="/ngoUpgrade"
              element={
                <SideMenu>
                  <SignedinUpgrade />{" "}
                </SideMenu>
              }
            />
            <Route path="/TermsConditions" element={<TermsConditions />} />
            <Route
              path="/accounts/additional_info"
              element={<Additionalinfo />}
            />
            <Route
              path="/cause/additional_info"
              element={<CauseAdditionalinfo />}
            />
            {/* ...............................ADMIN.................. */}
            <Route
              path="/adminDashboard"
              element={
                <SideMenuadmin>
                  <Admin_Dashboard />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_Cause"
              element={
                <SideMenuadmin>
                  <Admin_Cause />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_Profile"
              element={
                <SideMenuadmin>
                  <Admin_Profile />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_NGO"
              element={
                <SideMenuadmin>
                  <Admin_NGO />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_ViewCorp"
              element={
                <SideMenuadmin>
                  <Admin_ViewCorp />
                </SideMenuadmin>
              }
            />
            <Route
              path="/AdminViewCause"
              element={
                <SideMenuadmin>
                  {" "}
                  <Admin_ViewCause />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_Corp"
              element={
                <SideMenuadmin>
                  {" "}
                  <Admin_Corp />
                </SideMenuadmin>
              }
            />
            <Route
              path="/AdminViewProfile"
              element={
                <SideMenuadmin>
                  <Admin_ViewProfile />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_CorpDonation"
              element={
                <SideMenuadmin>
                  <Admin_CorpDonation />
                </SideMenuadmin>
              }
            />
            <Route
              path="/NGOMembership"
              element={
                <SideMenuadmin>
                  <Admin_NGOMembership />
                </SideMenuadmin>
              }
            />
            <Route
              path="/admincauseDonation"
              element={
                <SideMenuadmin>
                  <Admin_Donation />
                </SideMenuadmin>
              }
            />
            <Route
              path="/MembershipHistory"
              element={
                <SideMenuadmin>
                  <Admin_MembershipHistory />
                </SideMenuadmin>
              }
            />
            <Route
              path="/MembershipStatus"
              element={
                <SideMenuadmin>
                  <Admin_MembershipOrderstatus />
                </SideMenuadmin>
              }
            />
            <Route
              path="/MembershipProfile"
              element={
                <SideMenuadmin>
                  <Admin_MembershipProfile />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_CorpInterest"
              element={
                <SideMenuadmin>
                  <Admin_CorpIntrest />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_CorpIntrestedProfile"
              element={
                <SideMenuadmin>
                  <Admin_CorpIntrestedprofile />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_CorpInterstedList"
              element={
                <SideMenuadmin>
                  <Admin_CorpList />
                </SideMenuadmin>
              }
            />
            <Route
              path="/paymentStatus"
              element={
                <SideMenuadmin>
                  <Admin_PaymentStatus />
                </SideMenuadmin>
              }
            />
            {/* ...............................CORPS.................. */}
            <Route
              path="/corpDashboard"
              element={
                <SideMenucorp>
                  <Corp_Dashboard />
                </SideMenucorp>
              }
            />
            <Route
              path="/Corp_Profile"
              element={
                <SideMenucorp>
                  <Corp_Profile />
                </SideMenucorp>
              }
            />
            <Route
              path="/Corp_NGO"
              element={
                <SideMenucorp>
                  <Corp_NGO />
                </SideMenucorp>
              }
            />
            <Route path="/Corp_Payment" element={<Corp_Payments />} />
            <Route
              path="/Corp_Cause"
              element={
                <SideMenucorp>
                  <Corp_Cause />
                </SideMenucorp>
              }
            />
            <Route
              path="/Corp_NGOCause"
              element={
                <SideMenucorp>
                  <CorpNGOCause />
                </SideMenucorp>
              }
            />
            <Route
              path="corp/accounts/additional_info"
              element={<Corp_Addinfo />}
            />
            <Route
              path="/accounts/corp/verify_email"
              element={<CorpAccountVerify />}
            />
            <Route path="/accounts/verify_email/" element={<VerifyEmail />} />
            <Route
              path="/accounts/account_verify/"
              element={<AccountVerify />}
            />
            <Route
              path="/accounts/reset_password"
              element={<ResetPassword />}
            />
            {/* ------------------Individuals------------------------ */}
            {/* ...............................Error.................. */}
            <Route path="*" element={<Error404 />} />
            {/* <Route path="/ccavResponseHandler" element={<Paymentsuccess />} />
        <Route path="/paymentcancel" element={<Paymentfailure />} /> */}
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
